/*Snippets CSS*/

ul.bulleted >li {
  list-style-type: circle;
  margin-left: 20px;
}

ol.numbered >li {
  list-style-type: decimal;
  margin-left: -20px;
}

.snippet-btn {
  display: block;
}

.disclaimer {
  font-size: 0.8em;
  line-height: 2em;
}

@media only screen and (min-width: 40.0625em) {
.snippet-flag {
  position: absolute;
  left: 45px;
  top: 100px;
  padding: 0.25rem 0.75rem;
  text-align: center;
  background-color: #808285;
  font-weight: 600;
  color: #fff;
  min-width: 4rem;
  font-weight: 600;
  z-index: 10;
  max-height: 40px;
}
}