//navigation
@import '../settings';
@import 'vars';
@import 'mixins';
@import 'colors';

// global

// z index added on 10/24
#primary > li > ul.dropdown{
    background-color: $white;
    z-index: 201; 
}

// .hide--desktop class added 10/24
@media (min-width: 40.0625em) {
  #mobile-header__buttons {
    display: none;
  }
}

// NAV 1  //

nav.primary-nav{
  background-color: $grey-light;
   li{
      list-style: none;
   }
   ul.primary-nav__ul{
       width:100% !important;
   }
   
   .top-bar-section .has-dropdown > a{
                
        &:after{
            border: none;
            // content: '\f100';
            background-image: url("../../img/arrow-dropdown-sm-wh.svg");
            background-position: center center;
            background-repeat: no-repeat;
           // position: absolute;
            top: 2px;
            left: 7px;
            height: 15px;
            display: inline-block;
            width: 20px;
            padding-left: 1rem;
            position: relative;
            margin: 0;
        }
   }
   
   .top-bar-section > ul > li{
       background-color: $grey-light;
       border-right: 2px solid $white;
       &:hover, &:active, &:focus{
           background-color: transparent;
           > a{
               background-color: $grey-med;
           }
       }
       &:first-child{
           //padding-left: 0;
       }
       
       &:last-child{
           border-right: none;
          // padding-right: none;
       }
       a{
           color: $black !important;
           background-color: $grey-light;
           padding: 1rem; //default padding
           font-size: 0.9rem;
           text-transform: uppercase;
           text-align: center;
           &:hover, &:active, &:focus{
               color: $black;
               background-color: $grey-med;
           }
       }
       
       > ul li a{
           padding: 0.5rem 1rem;
       }
   }
}

.list-horz{
    li, dd{
        display: inline-block;
        padding-right: 1rem;
        margin-bottom: 0.6rem;
    }
    li:last-child, dd:last-child{
        padding-right: 0;
    }
}

.list--dividers{
    li, dd{
        &:after{
            content: '|';
            display: inline-block;
            padding-left: 1rem;
        }
    }
    li:last-child, dd:last-child{
        &:after{
            content: '';
            padding-left: 0;
        }
    }
}

// ============== NAV 2  ============= //

nav.primary-nav-2{
  background-color: $grey-light;
   li{
      list-style: none;
   }
   ul.primary-nav__ul{
     float: right;
	 padding-top: 0.4rem;
	 padding-bottom: 0.4rem;
	 
   }
   
   .top-bar-section .has-dropdown > a{
         
            
        &:after{
            border: none;
            content: '\f100';
           font-family: "ubericons";
		   font-size: 0.6rem;;
		   color: $grey-dark;
          
           // position: absolute;
            top: -2px;
            right: 0px;
            height: 15px;
            display: inline-block;
            width: 20px;
            padding-left: 1rem;
            position: relative;
            margin: 0;
        }
   }
   ul > ul{
	   float: left;
   }
   .top-bar-section > ul > li{
       background-color: $grey-light;
       border-right: 1px solid $grey-med;
       &:hover, &:active, &:focus{
           background-color: transparent;
           > a{
               background-color: transparent;
			   color: $grey-dark;
           }
       }
       &:first-child{
           //padding-left: 0;
       }
       
       &:last-child{
           border-right: none;
          // padding-right: none;
       }
   
	   
	   a{
           color: $black;
           background-color: $grey-light;
           padding: .6rem 1.2rem; //default padding
           font-size: 0.9rem;
           text-transform: uppercase;
           text-align: center;
           &:hover, &:active, &:focus{
               color: $grey-dark;
               background-color: $grey-med;
           }
       }
	   
	     > a{
		   &:hover, &:active, &:focus{
             color: $grey-dark;  
			//text-decoration: underline;
			 background-color: transparent;
		   }
	   }
       
       > ul li a{
           padding: 0.5rem 1rem;
       }
   }
}


// ================== NAV 3 =================== //

// removed ul> li ul border, border top width, and box shadow on 10/24
nav.primary-nav-3{
  background-color: $white;
   li{
      list-style: none;
   }
   ul.primary-nav__ul{
     float: right;
	 padding-top: 0.8rem;
	 padding-bottom: 0.8rem;
	 
   }
   
   .top-bar-section .has-dropdown > a{
         
            
        &:after{
            border: none;
            content: '\f100';
           font-family: "ubericons";
		   font-size: 0.6rem;;
		   color: $grey-light;
          
           // position: absolute;
            top: -2px;
            right: 0px;
            height: 15px;
            display: inline-block;
            width: 20px;
            padding-left: 1rem;
            position: relative;
            margin: 0;
        }
   }
   ul > li  ul{
	   float: left;
   }
   .top-bar-section > ul > li{
       background-color: $white;
            > a{
               background-color: transparent; 
            }
       &:hover, &:active, &:focus{
           background-color: transparent;
           > a{
               background-color: transparent;
			   //text-decoration: underline;
           }
       }
       &:first-child{
           //padding-left: 0;
       }
       
       &:last-child{
           border-right: none;
          // padding-right: none;
       }
   
	   
	   a{
           color: $black !important;
           background-color: $white;
           padding: .6rem 1.2rem; //default padding
           font-size: 0.9rem;
           text-transform: uppercase;
           text-align: center;
           &:hover, &:active, &:focus{
               color: $black;
               background-color: $grey-lighter;
           }
       }
	   
	     > a{
		   &:hover, &:active, &:focus{
               
			//text-decoration: underline;
			 background-color: transparent;
		   }
	   }
	   
	  
       
       > ul li a{
           padding: 0.5rem 1rem;
       }
   }
}



// ================ NAV UTIL CLASSES ================== //

// changed padding-top to 1rem from 5rem on 10/24
.header-lower-bar
{
	background-color: $grey-light;
	padding-top: 1rem;
	padding-bottom: .5rem;
}


.list-horz{
    li, dd{
        display: inline-block;
        padding-right: 1rem;
    }
    li:last-child, dd:last-child{
        padding-right: 0;
    }
}

@media only screen and (max-width: 640px) {
    li, dd{
        display: inline-block;
        font-size: 0.75rem;
    }
  }

.list--dividers{
    li, dd{
        &:after{
            content: '|';
            display: inline-block;
            padding-left: 1rem;
        }
    }
    
    li:last-child, dd:last-child{
        &:after{
            content: '';
            padding-left: 0;
        }
    }
}




// ============== FOOTER NAV ================ //

ul.nav__bottom{
	> li{
		display: block;
		text-transform: uppercase;
		float: left;
		
		a{
			@include utransition(background-color 300ms);
			padding: 1.6rem 1rem;
			font-size: 1rem;
			display: block;
		}
	}
	
	border-bottom: 1px solid $grey-med;
	
}


.notused{
	//float: right;
	&:before{
		@include utransition(transform 300ms);
		border-top: 5px solid $grey-dark;
		content: '';
		padding-top: 8px;
		border-bottom: 5px solid $grey-dark;
		margin-bottom: 8px;
		display: block;
		position: relative;
	
		//background-color: $secondary-color;
		
	}
	&:after{
		//border-top: 3px solid $grey-dark;
		content: 'MENU';
		color: $grey-dark;
		text-align: center;
		font-size: .9rem;
		
		display: block;
		//background-color: $secondary-color;
		
		}
	
}