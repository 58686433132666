@import 'colors';


//Padding/ Margins 
$padding-xlg:rem-calc(80);
$padding-lg:rem-calc(66);
$padding-med:rem-calc(30);
$padding-sm: 1rem;;
$padding-xsm: 0.5rem;

//Button colors
$button-color: $grey-darker;
$button-color-sub: $grey-med;
$social-button-color: $grey-dark;
$button-text-color: $white;
$button-color-hover: $grey-dark;


//Site Key colors
$primary-color: $grey-dark;
$secondary-color: $grey-med;
$tertiary-color: $grey-light;

//Setup background colors
$background-light: $grey-lighter;
$background-medium: $grey-light;
$background-dark: $grey-dark;

//Border colors
$border-color: $grey-light;
$border-color-dark: $grey-dark;

//Mobile modifiers
$padding-mobile-modifier: 0.65;
$font-mobile-modifier: 0.85;

