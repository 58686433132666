/*
*
* Reset
*
*/

li{
   list-style: none;
}

a, a:hover,a:link,a:focus.a:visited{
    text-decoration: none;
}

img{
    border: none;
}

ul,dl,li,dd{
    margin: 0;
    padding: 0;
}

h1, h2, h3, h4, h5, h6{
    margin: 0;
    padding: 0;
}

.breadcrumbs-wrapper {
    margin-top: 20px;
    margin-bottom: 10px;
}

/*added on 10/24 edited by AW on 11/14*/
.breadcrumbs li {
    display: inline-block;
}

.breadcrumbs > *:first-child:before {
    content: " ";
    margin: 0 1.0rem 0 1.75rem;
}

.breadcrumbs li:before {
    content: "/";
    margin: 0 0.75rem;
    position: relative;
}

.breadcrumbs li:first-child #breadcrumbs-employee {
    margin-left: -3.0rem;
}

ul#breadcrumbs-employee {
    margin-left: -2.5rem;
}