/*
	Model Specific landing pages
	Alice/ Ashton
*/


.wrapper {
	position: relative
}

.model-title {
	bottom: 0;
  text-align: center;
}

.model-title h2 {
	text-align: center;
}

.specs {
  padding: 0.5rem 0.5rem;
  margin: 0 0.5rem 0.5rem 0;
  display: inline-block;
  font-size: 0.9rem;
  text-align: center;
  align: right;
}


// .tabs {
//   margin-bottom: 0 !important;
//   margin-left: 0; }

// .tabs:before, .tabs:after {
//     content: " ";
//     display: table;
// 	}

// .tabs:after {
//     clear: both;
//   }

.tabs dd, .tabs .tab-title {
    float: left;
    list-style: none;
    // margin-bottom: 0.7rem !important;
    position: relative;
  }
    
.tabs dd > a, .tabs .tab-title > a {
      display: block;
      background-color: #EFEFEF;
      color: #222222;
      font-size: 0.88889rem;
      padding: 0.88889rem 1rem;
    }

.tabs dd > a:hover, .tabs .tab-title > a:hover {
        background-color: #e1e1e1;
      }

.tabs dd.active > a, .tabs .tab-title.active > a {
      background-color: #6D6F71;
      color: #FFFFFF;
      font-weight: bold;
}

// @media only screen and (max-width: 35.61111em) {
// 	.tabs {
// 		display: none;
// 	}
// 	}

@media only screen and (min-width: 35.61111em) {
	.accordian-mobile{
		display: none;
	}
	}
  
.tabs.vertical dd, .tabs.vertical .tab-title {
    position: inherit;
    float: none;
    display: block;
    top: auto;
	}

.tabs-content {
  margin-bottom: 1.33333rem;
  width: 100%;
  }

.tabs-content:before, .tabs-content:after {
    content: " ";
    display: table;
  }

.tabs-content:after {
    clear: both;
  }
  
.tabs-content > .content {
    display: none;
    float: left;
    padding: 0.3rem 0;
    width: 100%;
  }
  
.tabs-content > .content.active {
    display: block;
    float: none;
  	}

.tabs-content > .content.contained {
  padding: 0.83333rem;
  }

.tabs-content.vertical {
  display: block;
	}

.tabs-content.vertical > .content {
  padding: 0 0.83333rem;
  }

@media only screen and (min-width: 35.61111em) {
  .h2 {
    margin-bottom: 1rem;
  }
  
  .specs {
    font-size: 1.1rem;
    text-align: left;
  }

  .tabs.vertical {
    float: left;
    margin: 0;
    margin-bottom: 1.25rem !important;
    max-width: 100%;
    width: 100%;
    }

  .tabs-content.vertical {
    float: left;
    margin-left: -1px;
    max-width: 80%;
    padding-left: 1rem;
    width: 80%;
    } 
  }

.no-js .tabs-content > .content {
  	display: block;
  	float: none;
  }

  p:empty {
    display:none;
  }


// Old styles pulled 

.slide { 
	width: 1145px; 
	margin-left: auto; 
	margin-right: auto; 
	position:relative;
	}

.slideImageOne {
	width: 100%; 
	margin-left:auto; 
	margin-right: auto;
	}	

@media (max-width: 836px) {
.slide { 
	width: 100%; 
	margin-left: auto; 
	margin-right: auto; 
	position: static;
	}

}	
	
@media (max-width:1145px) {
.slide img {
	width: 100%;
	}
}
