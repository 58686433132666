
@import '../settings';
@import 'vars';
@import 'mixins';
@import 'colors';


// ==== carousel =====


.ms-staff-carousel{
	max-width: 100% !important;	
}

// js is setting a max-width on this in the uber theme, but in the concept site, there was no problem. I haven't figured about what is happening
// but we need to force it to the correct styles - hard handed coding
.ms-inner-controls-cont{
  max-width: inherit !important;
}

.ms-staff-carousel .ms-nav-prev{
    left: 25px;
	  background-image: url("../../img/arrow-lft-black.svg");
	  background-position: center center;
	  background-color: $white;
	  @include border-radius(50%);
	  width: 60px;
	  height: 60px;
    @include opacity(0.5);
    @include utransition(opacity);
    &:hover{
      @include opacity(1); 
    }
  }

.ms-staff-carousel .ms-nav-next{
    right: 25px;
    background-image: url("../../img/arrow-rt-black.svg");
	  background-position: center center;
	  background-color: $white;
    @include border-radius(50%);
	  width: 60px;
	  height: 60px;
	  @include opacity(0.5);
    @include utransition(opacity);
    &:hover{
     @include opacity(1);  
   }    
 }


//old remove?

#carousel {
  width: 100%;;
  height: 300px;
  display: relative;
  
  p{
   display: none;
   position: absolute;
   bottom: -20px;
   text-align: center;
   width: 100%;
   
 }
}
#carousel img {
  display: none; /* hide images until carousel prepares them */
  cursor: pointer; /* not needed if you wrap carousel items in links */
}

// ===== end carousel ========