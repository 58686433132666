@mixin box-shadow($shadow) {
    -webkit-box-shadow: $shadow;
    -moz-box-shadow: $shadow;
    box-shadow: $shadow;
}
    
    
@mixin border-radius($radius) {
  border-radius: $radius;
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  -o-border-radius: $radius;
}

@mixin utransition($transition){
  -webkit-transition: $transition;
     -moz-transition: $transition;
      -ms-transition: $transition;
       -o-transition: $transition;
          transition: $transition;
}

@mixin transform($transforms) {
	   -moz-transform: $transforms;
	     -o-transform: $transforms;
	    -ms-transform: $transforms;
	-webkit-transform: $transforms;
            transform: $transforms;
}
 
//depends on modernizer - swaps svg for png/png2x for browsers 
// $image-path: '../img' !default;
// $fallback-extension: 'png' !default;
// $retina-suffix: '@2x';
// @mixin background-image($name, $size:false){
//     background-image: url(#{$image-path}/#{$name}.svg);
//     @if($size){
//         background-size: $size;
//     }
//     .no-svg &{
//         background-image: url(#{$image-path}/#{$name}.#{$fallback-extension});

//         @media only screen and (-moz-min-device-pixel-ratio: 1.5), only screen and (-o-min-device-pixel-ratio: 3/2), only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
//           background-image: url(#{$image-path}/#{$name}#{$retina-suffix}.#{$fallback-extension});
//         }
//     }
// }


//animation - two part use @include keyframes + @include animation
@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }
    @-moz-keyframes #{$animation-name} {
        @content;
    }  
    @-ms-keyframes #{$animation-name} {
        @content;
    }
    @-o-keyframes #{$animation-name} {
        @content;
    }  
    @keyframes #{$animation-name} {
        @content;
    }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};      
}

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}


// works with both nested and unnested
@mixin optional-at-root($sel) {
  @at-root #{if(not &, $sel, selector-append(&, $sel))} {
	  //checks if it is at root, then if not, insert parent
    @content;
  }
}

@mixin placeholder {
  @include optional-at-root('::-webkit-input-placeholder') {
    @content;
  }

  @include optional-at-root(':-moz-placeholder') {
    @content;
  }

  @include optional-at-root('::-moz-placeholder') {
    @content;
  }

  @include optional-at-root(':-ms-input-placeholder') {
    @content;
  }
}


@mixin clearfix{
	&:before,
    &:after {
        content: "";
        display: table;
    }
    &:after {
        clear: both;
    }
}

//another way to prefix -- I'm not sure which one is best
@mixin prefix($declarations, $prefixes: ()) {
  @each $property, $value in $declarations {
    @each $prefix in $prefixes {
      #{'-' + $prefix + '-' + $property}: $value;
    }

    // Output standard non-prefixed declaration
    #{$property}: $value;
  }
}