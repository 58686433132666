
// Flex code for primary nav
#primary{
  display: flex;
  flex-wrap: wrap;
  flex-flow: row ltr;
  justify-content: flex-start;

  > li{
    flex-grow: 1;
    flex-basis: auto; 
    display: flex;
    align-items: flex-start;
  }

  li > ul.dropdown{
    // must match height of menu bar to compensate for flex box layout
    top: 45px !important; 

  }
}

@media (min-width:1440px){
	#primary li > ul.dropdown{
		top: 45px !important;
	}
}