@import '../settings';
@import 'vars';
@import 'mixins';
@import 'colors';



%button{
	background-color: $button-color;
    @include border-radius(0.3rem 0.3rem);
    color: $white;   
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    margin-bottom: 0;
	@include utransition(background-color 300ms);
	&:hover, &:focus{
		color: $button-text-color;
	}
}

a.close {
	color: white
}


button, .button, .btn{
   @extend %button;
   font-size: 0.8rem;
   padding: $padding-sm;
   
   color: $white;
		&:hover{
			color: $white;
		}
    
 
}
.button--light{
        background-color: $grey-light !important;
    }

button.button.contact {
	float: right;
}    

.button--sm{
	@extend %button;
	padding: $padding-xsm;
    font-size: 0.7rem;
}

input[type="submit"]{
   @extend .button;
}

// buttons are biggers on larger displays
@media #{$medium-only}{
	button, .button{
		padding: $padding-sm;
		color: $button-text-color;   
		font-size: 0.9rem;	
	}
}

@media #{$large-up}{
	button, .button{
		padding: $padding-sm;
		color: $button-text-color;   
		font-size: 0.9rem;
	}
}

.button:focus {
	background-color: $grey-light;
}

