

@import '../settings';
@import 'vars';
@import 'mixins';
@import 'colors';

//   begin slider via slick.js 

.slider{
	blockquote{
		border-left: 0px;
		padding: 0 $padding-med;
	}
}

.slick-prev, .slick-next{
   position: absolute;
   top: 40%;
   background-color: transparent;
   border: none;
   @include transform(translate(0, -50%));
   cursor: pointer;
   outline: none;
   width: 20px;
   height: 37px;
   color: transparent;
   &:hover, &:active, &:focus{
  
    color: transparent;
  }
}

button.slick-prev{
  left: 15px;
  background: url("/Themes/uber/img/arrow-l.svg") no-repeat;
  z-index: 200;
}

button.slick-next{
  right: 15px;
  background: url("/Themes/uber/img/arrow-r.svg") no-repeat;
  background-position: center right;
  z-index: 201;
}

button.slick-next, button.slick-prev{
	background-position: center center;
    background-color: rgba(0,0,0,.3);
    /* padding: 10px; */
    width: 60px;
    height: 60px;
    border-radius: 50%;
}
.uber-slick-dots{
 	// default block of nav items
	display: none;
	// width: 60%;
	// margin-left: auto;
	// margin-right: auto;
	// text-align: center;	 
}

// .uber-slick--nav-over .uber-slick-dots{
//   bottom: 15px;
//   @include transform(translate(50%, 0));
//   width: 300px;
//   right: 50%;

// }

.slider--bgw{
  button.slick-prev{
    background-image: url("/Themes/uber/img/arrow-lft-black.svg");
    @include opacity(0.5);
    @include utransition(opacity);
    &:hover{
      @include opacity(1); 
    }
  }

  button.slick-next{
    background-image: url("/Themes/uber/img/arrow-rt-black.svg");
    @include opacity(0.5);
    @include utransition(opacity);
    &:hover{
     @include opacity(1);  
   }    
 }
}

.slick-slide img{
	margin: 0 auto;
}

.slider{
		 
	@include clearfix;
	li{
		list-style: none;
		display: inline-block;
	}
	ul.uber-slick-dots{
		display: block;
		text-align: center;
		margin: 0 auto;
		padding-bottom: $padding-lg;

	}	
   ul.uber-slick-dots {
	  li{
		  width: 25px;	
	   }
      button{
      	background-color: transparent;
      	color: transparent;
	  	display: inline-block;
	  	text-align: center;
	  	padding: 6px;
	  	margin-bottom: 0;
		
	  }
	  
  	button:before{
    	content:' ';
   	 	width: 0.75rem;
   	 	height: 0.75rem;
   		background-color: $grey-med;
    	@include border-radius(50%);
    	display: inline-block;
		text-align: center;
  	}
  
  	li.slick-active button:before{
   		 background-color: $grey-darker;
  	}
  
  	// button:hover{
   // 	 &:before{
   //   	 background-color: $grey-darker;
   //  	} 
  	//   }
	}
	.slider-content{
		padding-top: $padding-sm;
		padding-bottom: $padding-sm;
		
	}	
	.slider--set--height{
		min-height: 470px; // need to set fixed height for bg
	
	}
	.slider--padding{
		padding-right: 24px;
		padding-left: 24px;
	}
}
//slide with dark background
.slider--bg-dark{
	ul.uber-slick-dots{	
		button:before{
    		content:' ';
   			background-color: $white;
		}
  
  		li.slick-active button:before{
   		 	background-color: $grey-med;
  		}
  
  		button:hover{
   			 &:before{
     		 background-color: $grey-light;
    		} 
  	  	}
	}
}

//styles for larger screens
@media #{$large-up}{
	button.slick-prev{
		left: 15px;		
	}

	button.slick-next{
		right: 15px;
	}
	
	.slider .slider--padding{
		padding-right: 24px;
		padding-left: 24px;
	}
	
	.uber-slick-dots{
 	 	bottom: 15px;
	}
}

.bg--image{
	background-image: url("http://placehold.it/1200x600");
	//background-image: url("http://lorempixel.com/1200/600/abstract");
	background-position: center center;
	background-size: cover;
}


.ms-view{
	//override correctly To DO; get rid of important tags
	background-color: $grey-lighter !important;
}


.cp-slider{
	
	&__nav{
		width: 50%;
		position: relative;
		top: -80px;
	}
	&__content{
		height: 100%;
	}
}
.ms-bullets{
	bottom: -40px !important;
	.ms-bullet{
		background-color: transparent;
      	color: transparent;
	  	display: inline-block;
	  	text-align: center;
	  	padding: 0 0.5rem 0 0;
	  	margin-bottom: 0;
		  
		&:before{
			content:' ';
			width: 0.75rem;
			height: 0.75rem;
			background-color: $grey-med;
			@include border-radius(50%);
			display: inline-block;
		}
		&:hover{
			&:before{
				background-color: $grey-darker;
			}
		}
	}	
	.ms-bullet-selected{
		&:before{	
			background-color: $grey-darker;
		}
	}
	
} 

.ms-slide-loading{
	display: none;
}

.cp-slider__nav{
	position: relative;
	
	.ms-prev, .ms-next{
		background-position: center center;
		background-color: rgba(0,0,0,.5);
		background-repeat: no-repeat;
		/* padding: 10px; */
		width: 30px;
		height: 30px;
		border-radius: 50%;
	}

	.ms-prev{
		background-image: url("/Themes/uber/img/arrow-l.svg");
		@include opacity(0.5);
		@include utransition(opacity);
		&:hover{
			@include opacity(1); 
		}
		position: absolute;
		left: 1rem;
	}

  .ms-next{
		background-image: url("/Themes/uber/img/arrow-r.svg");
		@include opacity(0.5);
		@include utransition(opacity);
		&:hover{
			@include opacity(1);  
		} 
		position: absolute;
		right: 1rem;   
	}	
}



//   end slider 

