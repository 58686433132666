@import '../settings';
@import 'vars';
@import 'mixins';
@import 'colors';	
	
	
	.mm-opened .off-canvas-button{
		@include utransition(transform 300ms);
		&:before{
			@include utransition(transform 300ms);
			@include transform(rotate(45deg));
		}
	}
	
	
	
	.off-canvas-button span.mobile-bar{
		@include utransition(opacity 300ms);
		@include utransition(transform 300ms);
		border-top: 5px solid $grey-dark;
		padding-bottom: 8px;
		display: block;
		
	}
	
	.mm-opened{
		span.mobile-bar:first-child{
			position: absolute;
			top: 16px;
			width: 40px;
			left: 0px;
			padding: 0;
			@include transform(rotate(45deg));
		}
		span.mobile-bar:nth-child(2){
			position: absolute;
			top: 16px;
			width: 40px;
			padding: 0;
			right: 5px;
			@include transform(rotate(-45deg));
		}
		span.mobile-bar:nth-child(3){
			@include opacity(0); 
		}
		
		
		
	}

	.off-canvas-button{
	display: block;
	width: 45px;
	margin-top: 0.55rem;
	position: absolute;
	height: 30px;
	text-align: center;
	// @include utransition(border-color 300ms);
	// &:hover{
	// 	span.mobile-bar{
	// 		border-color: $black;
	// 		}		
			
	// 	}
	}
	
.mobile-menu{
	.mm-listview{
		font-size: 1rem;
	}
}	
	
.logo-block{
	padding: 0 0 0 65px ;
	text-align: center;
	display: block;
}

img.logo {
	padding-left: 0.8rem;
}

@media #{$medium}{
	.logo-block{
		padding: 0;
		display: inline-block;
	}
}




@media #{$large}{
	.off-canvas-button{
		display: none;
	}
	.logo-block{
		padding: 0;
		text-align: center;
		display: inline-block;
	}
}
