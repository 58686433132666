@import '../settings';
@import 'vars';
@import 'mixins';
@import 'colors';

/*
  Icon Font: ubericons
*/

@font-face {
  font-family: "ubericons";
  src: url("/Themes/uber/fonts/ubericons.eot");
  src: url("/Themes/uber/fonts/ubericons.eot?#iefix") format("embedded-opentype"),
       url("/Themes/uber/fonts/ubericons.woff") format("woff"),
       url("/Themes/uber/fonts/ubericons.ttf") format("truetype"),
       url("/Themes/uber/fonts/ubericons.svg#ubericons") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "ubericons";
    src: url("/Themes/uber/fonts/ubericons.svg#ubericons") format("svg");
  }
}

[data-icon]:before { content: attr(data-icon); }

[data-icon]:before,
.icon-arrow-dropdown:before,
.icon-arrow-left:before,
.icon-arrow-left-copy:before,
.icon-arrow-right:before,
.icon-arrow-right-copy:before,
.icon-envelope:before,
.icon-facebook:before,
.icon-googleplus:before,
.icon-instagram:before,
.icon-linkedin:before,
.icon-linkedin-sq:before,
.icon-marker:before,
.icon-person:before,
.icon-phone:before,
.icon-pinterest:before,
.icon-pinterest-p:before,
.icon-triangle:before,
.icon-twitter:before,
.icon-youtube:before {
  display: inline-block;
  font-family: "ubericons";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

.icon-arrow-dropdown:before { content: "\f100"; }
.icon-arrow-left:before { content: "\f101"; }
.icon-arrow-left-copy:before { content: "\f102"; }
.icon-arrow-right:before { content: "\f103"; }
.icon-arrow-right-copy:before { content: "\f104"; }
.icon-envelope:before { content: "\f105"; }
.icon-facebook:before { content: "\f106"; }
.icon-googleplus:before { content: "\f107"; }
.icon-instagram:before { content: "\f108"; }
.icon-linkedin:before { content: "\f109"; }
.icon-linkedin-sq:before { content: "\f10a"; }
.icon-marker:before { content: "\f10b"; }
.icon-person:before { content: "\f113"; }
.icon-phone:before { content: "\f10d"; }
.icon-pinterest:before { content: "\f10e"; }
.icon-pinterest-p:before { content: "\f10f"; }
.icon-triangle:before { content: "\f110"; }
.icon-twitter:before { content: "\f111"; }
.icon-youtube:before { content: "\f112"; }




.icon{
	&--lg{
		font-size: 2rem;
	}
}

%icons{
	color: #fff;
	
	background-color: $grey-dark;
	@include border-radius(50%);
	
	display: inline-block;
	text-align: center;
	
	position: relative;
	@include utransition(background-color 300ms);
	&:before{
		text-align: center;
		position: absolute;
		
		left: 0;
		width: 100%;
		text-align: center;
	}
	&:hover{
		background-color: $secondary-color;
	}	
}

.icons--bg--white{
	a{
		background-color: $white;
		color: $grey-dark;
		
		&:hover{
			background-color: $grey-light;
		}
	}
}

a.icon-marker.icon::before {
	left: 0.25rem;
}

// ==================== ICON BLOCKS =================== //

.icon--med, .icons--med a, .icons--med i, .icons--med span, .icon--med, .icons--med button{
	@extend %icons;
	font-size: 1.4rem;
	width: 48px;
	height: 48px;
	padding: 4px;
	line-height: 1.4rem;               
	
	&:before{
		top: 14px;	
	}
	
	&.icon-googleplus:before, &.icon-youtube:before{
			font-size: 1.2em;
			top: 16px;
		}
	&.icon-facebook:before{
		left: 6px;
	}		
}

.icons--sm a, .icons--sm i, .icons--sm span, .icons--sm button, .icon--sm{
	@extend %icons;
	font-size: 1rem;
	width: 42px;
	height: 42px;
	padding: 2px;
	line-height: 1rem;
	margin-right: 18px;
	
	&:before{
		top: 13px;	
	}
	
	&.icon-googleplus:before, &.icon-youtube:before{
			font-size: 1.2em;
			top: 15px;
		}
	&.icon-facebook:before{
		left: 5px;
	}		
}


// ==================  SINGLES ================ //

.icon--bg--white{
	
		background-color: $white;
		color: $grey-dark !important;
		
		&:hover{
			background-color: $grey-light;
		}
	
}


// ==================== Desktop sizing ================== //

// make smaller for non-mobile interfaces

@media #{$large}{
	.icon--sm, .icons--sm a, .icons--sm i, .icons--sm span, .icons--sm button{
		width: 36px;
		height: 36px;
		font-size: 1rem;
		margin-right: 10px;
		margin-bottom: 5px;
		
		&:before{
		top: 10px;	
		}
		
		&.icon-googleplus:before, &.icon-youtube:before{
			font-size: 1.1em;
			top: 13px;
		}
		&.icon-facebook:before{
			left: 4px;
		}	
	}
	
}


//================== no @Font-face ================== //

//fall back for Opera mini OS
.no-font{
	.icon, .icons--med a, .icons--sm a{
		background-position: center center;
		background-size: auto 40%;
		background-repeat: no-repeat;
		
		&:before{
			content: '';
		}
	}
}

.no-font{
	.icon-arrow-dropdown { background-image: url("../images/icons/arrow-dropdown.png"); }
	.icon-arrow-left { background-image: url("../images/icons/arrow-left.png"); }
	.icon-arrow-right { background-image: url("../images/icons/arrow-right.png"); }
	.icon-envelope { background-image: url("../images/icons/envelope.png"); }
	.icon-facebook { background-image: url("../images/icons/facebook.png"); }
	.icon-googleplus { background-image: url("../images/icons/googleplus.png"); }
	.icon-instagram { background-image: url("../images/icons/instagram.png"); }
	.icon-linkedin { background-image: url("../images/icons/linkedin.png"); }
	.icon-linkedin-sq { background-image: url("../images/icons/linkedin-sq.png");}
	.icon-marker { background-image: url("../images/icons/marker.png"); }
	.icon-phone {background-image: url("../images/icons/phone.png");}
	.icon-pinterest { background-image: url("../images/icons/icon-pinterest.png"); }
	.icon-pinterest-p { background-image: url("../images/icons/icon-pinterest-p.png"); }
	.icon-twitter { background-image: url("../images/icons/twitter.png"); }
	.icon-youtube { background-image: url("../images/icons/youtube.png"); }
}