/*
*   Utilities classes
*
*/
@import '../settings';
@import 'vars';
@import 'mixins';
@import 'colors';



//border modifiers



.border{
    &--left{
      border-left: solid 1px $border-color;
     
    }
	 &--left--spacing{
      border-left: solid 1px $border-color;
      padding-left: 1rem;
      margin-left: 1rem;
    }
	&--right--spacing{
      border-right: solid 1px $border-color;
      padding-right: 1rem;
      margin-right: 1rem;
    }
    &--right{
      border-right: solid 1px $border-color;
      //padding-right: 1rem;
     // margin-right: 1rem;
    }
    &--bottom-third{
        &:after{
            content: '';
            display: block;
            border-bottom: solid 1px $border-color;
            margin: 0 25%;
            padding-top: 1rem;
            margin-bottom: 1rem;
        }
    }
    
    &--bottom{
        border-bottom: solid 1px $border-color;
        padding-bottom: 1rem;
        margin-bottom: 1rem;
    }
}


// text box modifiers
.t-box{
    &--grey{
        padding: $padding-sm;
        background-color: $grey-light;
    }
}


// global remove padding
.pd--none{
    padding: 0 !important;
    margin: 0 !important;
}

// padding modifiers | padding vars set in vars.scss
@media #{$medium-up}{
    .pd{
        &--xsm{
            padding: $padding-xsm;
        }
        &--sm{
            padding: $padding-sm;
        }
        &--med{
            padding: $padding-med;
        }
        &--lg{
            padding: $padding-lg;
        }
        &--xlg{
            padding: $padding-xlg;
        }
        &--none{
            padding: 0 !important;
            margin: 0 !important;
        }
    }
//padding bottom
    .pdb{
        &--xsm{
            padding-bottom: $padding-xsm;
        }
        &--sm{
            padding-bottom: $padding-sm;
        }
        &--med{
            padding-bottom: $padding-med;
        }
        &--lg{
            padding-bottom: $padding-lg;
        }
        &--xlg{
            padding-bottom: $padding-xlg;
        }
    } 
    .pdtb--lg{
        padding: $padding-lg 0;
    }
	.pdtb--med{
        padding: $padding-med 0;
    }  
}


@media #{$small-only}{
    .pd{
        &--xsm{
            padding: $padding-xsm * $padding-mobile-modifier;
        }
        &--sm{
            padding: $padding-sm * $padding-mobile-modifier;
        }
        &--med{
            padding: $padding-med * $padding-mobile-modifier;
        }
        &--lg{
            padding: $padding-lg * $padding-mobile-modifier;
        }
        &--xlg{
            padding: $padding-xlg * $padding-mobile-modifier;
        }
        
    }
//padding bottom
    .pdb{
        &--xsm{
            padding-bottom: $padding-xsm * $padding-mobile-modifier;
        }
        &--sm{
            padding-bottom: $padding-sm * $padding-mobile-modifier;
        }
        &--med{
            padding-bottom: $padding-med * $padding-mobile-modifier;
        }
        &--lg{
            padding-bottom: $padding-lg * $padding-mobile-modifier;
        }
        &--xlg{
            padding-bottom: $padding-xlg * $padding-mobile-modifier;
        }
    }  
      .pdtb--lg{
        padding: $padding-lg*$padding-mobile-modifier 0;
    } 
	 .pdtb--med{
        padding: $padding-med*$padding-mobile-modifier 0;
    } 
	.pdb--mobile{
		padding-bottom: $padding-med*$padding-mobile-modifier;
	}
}

@media #{$large-up}{
	.pdb--lgscreen{
		padding-bottom: $padding-med;
	}
	.large--block{
	display: block;
	margin-bottom: 1rem;
	}
}


@media(min-width:80rem){
	.xlarge--block{
	display: block;
	margin-bottom: 1rem;
	}
}



.img{
	&--left{
		float: left;
		padding-right: 0.9375rem;
    	padding-bottom: 0.9375rem;
	}
	&--right{
		float: right;
		padding-left: 0.9375rem;
    	padding-bottom: 0.9375rem;	
	}
}




//let's try to remove this set if possible. top down structure
//padding top
.pdt{
    &--xsm{
        padding-top: $padding-xsm;
    }
    &--sm{
        padding-top: $padding-sm;
    }
    &--med{
        padding-top: $padding-med;
    }
    &--lg{
        padding-top: $padding-lg;
    }
    &--xlg{
        padding-top: $padding-xlg;
    }  
}

@media #{$medium-up}{
// margin modifiers | padding vars set in vars.scss
    .mg{
        &--xsm{
            margin-top: $padding-xsm;
            margin-bottom: $padding-xsm;
        }
        &--sm{
            // margin-top: $padding-sm;
            margin-bottom: $padding-sm;
        }
        &--med{
            margin-top: $padding-med;
            margin-bottom: $padding-med;
        }
        &--lg{
            margin-top: $padding-lg;
            margin-bottom: $padding-lg;
            
        }
        &--xlg{
            margin-top: $padding-xlg;
            margin-bottom: $padding-xlg;
        }
 
    }
    .mgb{
        
       &--xsm{
        margin-bottom: $padding-xsm;
       }
        &--sm{
             margin-bottom: $padding-sm; 
        }  
        &--med{
            margin-bottom: $padding-med;
        }
        
        &--lg{   
             margin-bottom: $padding-lg;
        }

        &--xlg{
             margin-bottom: $padding-xlg;
        }
		
    }
	
}

@media #{$medium-only}{
	.mgb--tablet{
		margin-bottom: $padding-sm;
	}
}



@media #{$small-only}{
// margin modifiers | padding vars set in vars.scss
    .mg{
        &--xsm{
            margin-top: $padding-xsm * $padding-mobile-modifier;
            margin-bottom: $padding-xsm * $padding-mobile-modifier;
        }
        &--sm{
            // margin-top: $padding-sm * $padding-mobile-modifier;
            margin-bottom: $padding-sm * $padding-mobile-modifier;
        }
        &--med{
            margin-top: $padding-med * $padding-mobile-modifier;
            margin-bottom: $padding-med * $padding-mobile-modifier;
        }
        &--lg{
            margin-top: $padding-lg * $padding-mobile-modifier;
            margin-bottom: $padding-lg * $padding-mobile-modifier;
            
        }
        &--xlg{
            margin-top: $padding-xlg * $padding-mobile-modifier;
            margin-bottom: $padding-xlg * $padding-mobile-modifier;
        }
 
    }
    .mgb{
        
       &--xsm{
        margin-bottom: $padding-xsm * $padding-mobile-modifier;
       }
        &--sm{
             margin-bottom: $padding-sm * $padding-mobile-modifier; 
        }  
        &--med{
            margin-bottom: $padding-med * $padding-mobile-modifier;
        }
        
        &--lg{   
             margin-bottom: $padding-lg * $padding-mobile-modifier;
        }

        &--xlg{
             margin-bottom: $padding-xlg * $padding-mobile-modifier;
        }
    }
	
	.mgb--mobile{
		  margin-bottom: $padding-sm * $padding-mobile-modifier; 
	}
}

// background modifiers
.bg{
   &--light{
       background-color: $background-light;
   } 
   &--dark{
       background-color: $background-dark;
   }
   &--med{
     background-color: $background-medium;
   }
}

.spacer{
	&--sm{
		margin-right: $padding-sm;
	}
	&--med{
		margin-right: $padding-med;
	}
	&--lg{
		margin-right: $padding-lg;
	}
}


//collection of hover states for objects and their children	
.hover{
	&--med{
		//change bg color for object with class
		@include utransition(background-color 300ms);
		&:hover{
			background-color: #E2E2E2;
			display: block;
		}
		
	}
	//for headers wrapped in a link, highlight on parent hover
	&--header{
		@include utransition(color 300ms);
		&:hover{
			h2, h3, h4, h5{
				color: $secondary-color;
			}
		}
		
	}
}	


.block{
	display: block;
}
.relative{
	position: relative;
}
	
// for 3 column layouts on larger screens, push columns inward

@media #{$medium-up}{
	.inset--med{
		padding-left: 8%;
		padding-right: 8%;
	}
	.inset--lg{
		padding-left: 12%;
		padding-right: 12%;
	}	
	.show--med, .block--med{
		display:block;
	}
}

.inline{
	display: inline-block;
}

.shift-down{
	position: relative;
	top: -10px;
}


@media #{$medium-up}{
    .hide--lg{
        display: none;
    }
}

@media #{$medium-only}{
	.hide--med{
		display: none;
	}
	.show--med, .block--med{
		display:block;
	}
}



@media #{$small-only}{
	//use this class to stack blocks vertically and centered in mobile
	//since column is set to 100%, we have to override the float and widths
	//the parent object needs to call t--center for it to work properly
	.small--stack-blocks{
		display: inline-block;
		float: none !important;
		width: inherit;
	}
	
	.hide--mobile, .hide--med{
		display: none;
	}
	.show--mobile, .block--sm, .block--med{
		display: inline-block;
        float: right;
	}
	
	.small-nofloat{
		float: none !important;
	}	
	
	.small--nofloat--center{
		float: none !important;
		margin-left: auto;
		margin-right: auto;
	}
	
	
	
	
}



