/*
*  Accordion styles
*
*/
//@import 'settings';
@import '../settings';
@import 'vars';
@import 'mixins';
@import 'colors';


.accordion.animated {
  .content {
    display: block;
    padding: 0 0.975em;
    overflow: hidden;
    transition: height .3s cubic-bezier(0.175, 0.885, 0.320, 1.275);;
    height: 0;
  }
}

.accordion .accordion-navigation > a:hover, .accordion dd > a:hover, .accordion a,
.accordion .accordion-navigation.active > a, .accordion dd.active > a {
  background: transparent;
}

  //demo
  .accordion {
    
    margin: 2em auto;
  }

  .accordion {
    
    dd > a {
      background: #fff;
      
    }
    dd > .content {
      
      > .inner-content {
        padding-top: 1rem;
        padding-bottom: 1rem;
      }
    }
  }
  .accordion{
   margin: 1rem 0;
   dd > a, dd > div{
     padding: 0; 
   }
   dd > a.plus-btn{
     border-bottom: 1px solid $grey-light;
     &:after{
       content: '+';
       float: right;
     }
   }
   dd > a.plus-btn.active{
     &:after{
       content: '-';
     }
   }
   dd{
     position: relative;
     display: inline;
   }
   dd > div.content{
      //    position: absolute;
      //    top: 1.2rem;
      //    background: $white;
    }
  }




// ============= Rewritten Slider ================= //

h4.plus-btn{
	color: $secondary-color;
}

.plus-btn{
     padding-bottom: 10px;
	 border-bottom: 1px solid $grey-light;
	 margin-bottom: 10px;
     &:after{
       content: '+';
       float: right;
	   background-color: $grey-light;
	   @include border-radius(50%);
	   display: block;
	   padding-top: 2px;
	   width: 30px;
	   height: 30px;
	   text-align: center;
     }
}

.active .plus-btn{
     &:after{
       content: '-';
     }
   }
   
@media #{$large}{
	.plus-btn{
		&:after{
		line-height: 11px;
		margin-top: 8px;
		width: 16px;
		height: 16px;

		}
	}
}   

.uberaccordionfaq{
	dd, li{
		margin-bottom: 1.5rem;
	}

  @media only screen and (max-width: 40em) {
    dd.faq__item {
      display: inline;
    }
  }
	
	dd > h4 , li > h4{
		cursor: pointer;
		padding: 1rem;
		background-color: $grey-light;
		//removed background svg in favor of ::after
    // background-image: url(../../img/faq--bg.svg);
		// background-size: 60px;
		// background-position: 0 0;
		// background-repeat: no-repeat;
    padding-left: 80px;
		position: relative;
		 &:before{
			 display: inline-block;
			position: absolute;
      z-index: 110;
			top: 15px;
			left: 24px;
			 font-family: 'ubericons';
			 content: '\f100';
			 color: $white;
			 @include transform(rotate(-90deg));
			 transform-origin: 50% 50%;
			 transition: 300ms;
      
		 }
     &:after{
       position: absolute;
       display: block;
       z-index: 100;
       content: '';
       height: 100%;
       width: 60px;
       top: 0;
       left: 0;
       background-color: $grey-dark;
     }
	}
	
	dd.active > h4:before, li.active > h4:before{

		@include transform(rotate(0deg));
		transform-origin: 50% 50%;
		transition: 300ms;
		top: 19px;
		left:21px;
		
		//animation: accordion 400ms linear 1;
	}
	.content{
		padding: $padding-sm;
	}
	
}

//set nav items
.uberaccordion{
	 dd > h4:first-child,  dd> h3:first-child,  dd> a:first-child,
	 li > h4:first-child,  li> h3:first-child,  li> a:first-child, {
		cursor: pointer;
		display: block;
	}
}