@import 'settings';
@import 'uber/vars';
@import 'uber/mixins';
@import 'uber/colors';


//typgraphy notes from mockups

//homepage
// h1 32pt
// h2 28pt
// h3 21pt --articles

//subhead/ meta info 18pt
// p 14pt
// button 18pt
// nav 16pt

//article page
/*
h1 - 42pt
meta - 21pt
p - 24pt
h2 - 33pt [aside]

*/


//typography

$sans: 'Lato', Helveltica, sans-serif;

html, body{
    font-size: 16px;
    font-family: $sans;
} 


//wrapper class for content styles
.type, .content{
    ul{
		padding-left: 1rem;
		li{
			list-style: disc;
			margin-bottom: 1rem;
			
		}
	}
	
}

$h1-size: rem-calc(32);
$h2-size: rem-calc(28);
$h3-size: rem-calc(22);
$h4-size: rem-calc(19);

blockquote{
	padding-left: 2rem;
	border-left: 2px solid $grey-light;
	font-size: 1.2rem;
}

.h1{
    font-size: $h1-size;
    margin-bottom: $padding-lg;
    font-weight: 400;
    text-transform: uppercase;
    color: $grey-darker;
}

@media #{$small-only} {
    h1 {
        font-size: 1.5rem;
    }
}

.h2{
    font-size: $h2-size;
    margin-bottom: $padding-sm;
    font-weight: 400;
}

.h3{
    font-size: $h3-size;
    margin-bottom: $padding-sm;
    font-weight: 400;
    color: $grey-darker;
}

.h4{
    font-size: $h4-size;
	 margin-bottom: $padding-xsm;
	 color: $grey-darker;
	
    
}

.h5{
    font-size: 1.3rem;
    margin: 0;
	margin-bottom: $padding-xsm;
}

.h6{
	font-weight: 700;
	font-size: 1rem;
	margin-bottom: $padding-xsm;
}

select{
    font-weight: 300;
}

.italic--med, .italic--lg{
    color: $grey-med;
    font-weight: 600;
}
.italic--lg{
    font-size: 1.2rem;
}

// type modifiers
.t{
    &--center{
        text-align: center;
    }
	&--left{
		text-align: left;
	}
    &--lg{
        font-size: 1.4rem;
    }
    &--med{
        font-size: 1.1rem;
    }
    &--sm{
        font-size: 0.8rem;
    }
	&--xsm{
		font-size: 0.7rem;
	}
	&--xlg{
		font-size: 1.8rem;;
	}
    &--date{
        color: $grey-dark;
        //text-transform: uppercase;
        font-weight: 600;
    }
    &--light{
        color: $white;
    }
    &--author{
      
    }
}

* {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// @media #{$large-up}{
// 	.t--date, .t--author{
// 		font-size: 1.2rem;
// 	}
// }
//override default colors for links

a{
	color: $black;
    &:hover{
        color: $primary-color;
    }
}

.coupon__time{
	style: italic;
	weight: 500;
	color: $secondary-color;
}

.icons--med > *{
    margin-right: 0.8rem;
    &:last-child{
        margin-right: 0;
    }
}

@media #{$small-only}{
    .h1{
        margin-bottom: $padding-sm; 
        font-size: $h1-size * $font-mobile-modifier;
    }
    .h2{
        margin-bottom: $padding-sm * $padding-mobile-modifier;
        font-size: $h2-size * $font-mobile-modifier;
    }
    .h3{
        margin-bottom: $padding-sm * $padding-mobile-modifier;
        
    }
	p{
		margin-bottom: 0.8rem;
	} 
}