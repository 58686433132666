@import 'settings';
@import 'uber/vars';
@import 'uber/mixins';
@import 'uber/colors';
  // general structure


  .section--full{
    min-width: 100%;
    //@include clearfix;
    
  }
  
  .section--featured{
    background-color: $grey-light;
  }
  .section--featured2{
    background-color: $grey-lighter;
  }
  .section--featured3{
    background-color: $grey-light;
  }
  .section--slider{
    background-color: $grey-dark;
  }
  .section--slider2{
    background-color: $white;
  }
  .section--box1{
    background-color: $grey-lighter;
  }
  .section--box1{
    background-color: $white;
  }

  .section--grey-light{
    background-color: $grey-light;
  }
  .section--white{
    background-color: $white;
  }
  .section--grey-dark{
    background-color: $grey-dark;
  }

  .section--carousel{
    background-color: $grey-light;
  }

  .section--news{
    background-color: $grey-light;
  }

  .bg--white{
    background-color: $white;
  }

  //used only for mockup
  img.fpo{
    background-color: $grey-dark;
    height: 100%;
    min-height: 100px;
    width: 100%;
    border: none;
  }


  $box-margin-side: 1/12 * 100%;
  $box-margin-inner: 1/24 * 100%;
  $box-2-width:5/12 * 100%;
  
  .box-round{
   @include border-radius(0.5rem);
   background-color: $grey-light;
 } 
 
 article.box-2 {
  padding: $padding-med;
  width: $box-2-width;
  &:first-of-type{
    margin-left: $box-margin-inner;
    margin-right: $box-margin-inner;
  }
  &:last-child{
    margin-right: $box-margin-inner;
    margin-left: $box-margin-inner;
  }
}

article.box-3 {
  padding: $padding-med;
  width: $box-2-width;
}

$box-3-width: 3/12 * 100%;
$box-3-gutter: 1/48 * 100%;

article.box-3{
  padding: $padding-med;
}

@media #{$large-up}{
  article.box-3{
    width: $box-3-width;
    margin: 0 $box-3-gutter;
    
    &:first-of-type{
      margin-left: $box-margin-side;
      
    }
    &:last-child{
      margin-right: $box-margin-side;
      
    }
  }
}
@media #{$medium-only}{
 article.box-3{
  width: 80%;
  margin: 2rem 10%;
	}
}


@media #{$small-only}{
  article.box-2, article.box-3, div.box-3{
    width: 90%;
    margin: 1rem 5%;
  }
}


.footer{
	width: 100%;
}
.footer__top{
  background-color: $grey-dark;
  
  color: $white;
  a{
    color: $white;
  }
}
.footer__bottom{
  background-color: $grey-light;
  padding: $padding-sm;
}

@media #{$small-only}{
	.footer__top{
		.row > div{
			border-width: 0; //override any borders
		}
	}
}


.map-canvas, .map{
  height: 400px;
  width: 100%;
  position: relative;
}
#googleMap{
  height: 100%;
  width: 100%;
}

@media #{$medium-only}{
	.map, .map-canvas{
		height: 300px;

	}
}

@media #{$small-only}{
	.map, .map-canvas{
		height: 250px;

	}
}

.social-icons{
  .icon{
    margin-right: 0.4rem;
  }
  
  a:last-child .icon{
    margin-right: 0;
  }    
}

.social-icons__title{
	font-weight: 600;
}


.flag{
 position: absolute;
 left: 15px;
 top: 30px;
 padding: 0.25rem 0.75rem;
 text-align: center;	 
 background-color: $background-dark; 
 font-weight: 600;
  color: $white;
 min-width: 4rem;
 font-weight: 600;
 z-index: 10;
 max-height: 40px; //overrides for Engagement (remove)
 
 &--r{
   right: 15px;
   left: auto;
 }
}

@media #{$small-only}{
	.flag{
		position: static;
		display: block;
		
	}
}

$feature-height: rem-calc(500);


.feature{
  position: relative; 
  overflow: hidden;
  //text-align: center;
  img{
	
	 width: 100%;
	 height: 100%;
	 max-width: none;
	 display: inline-block;
	
  }
}



@media (min-width: 1440px){
	.feature{
		height: $feature-height;
		//background: $background-light;
		position: relative;
		&--half{
			height: ($feature-height * 0.5) - 0.9375rem;
			}  
		}
}

@media #{$large-only}{
	.feature{
		height: $feature-height * 0.8;
		//background: $background-light;
		position: relative;
		&--half{
			height: (($feature-height * 0.8) * 0.5) - 0.9375rem;
			}  
		}
	
}

@media #{$medium-only}{
	.feature{
		height: $feature-height * 0.7;
		position: relative;
		&--half{
			height: (($feature-height * 0.7) * 0.5) - 0.9375rem;
			}  	
		}
	
}

@media #{$medium-up}{
  article:last-child div.feature--half{
    margin-top: 0.9375rem *2;
  }
}

@media #{$small-only}{
  .feature{
    margin-top: 0.9375rem;
  }
}

.title-box{
  position: absolute;
  bottom: $padding-sm;
  left: 0px;
  
  z-index: 100;
  padding: 0.5rem 2rem;
  min-width: 25%;
  background-color: rgba(255,255,255,.5);
}



@media #{$medium-only}{
	aside.sidebar{
		margin-top: 4rem;
	}
}


@media #{$small-only}{
	aside.sidebar{
		margin-top: 2rem;
	}
}

#main{
	position: relative;
}

@media only screen and (max-width: 640px) {
	#scrollToTop {
		display: none;
	}
}

#scrollToTop{
	position: absolute;
	top: 100px;
	left: 15px;
	z-index: 200;
	width: 40px;
	height: 40px;
	opacity: 0.5;
	//padding-left: 8px;
	@include border-radius(5px);
	background-color: $grey-med;
	display: none;
	cursor: pointer;
	@include utransition(background-color 300ms);
	
	&:after{
		font-family: 'ubericons';
		content: '\f100';
		font-size: 1.4rem;
		text-align: center;
		display: block;
		color: $white;
		@include transform(rotate(180deg));
		
	}
	&:hover{
		opacity: 1.0;
	}
}


.instagram-footer{
	width: 250px;
	  display: inline-block;
}


.mgb--med{
  margin-bottom: $padding-med;

}


.blockhover--full{
  display: block;
  visibility: hidden;
  height: 100%;
  width: 100%;
  background-color: rgba(184, 83,184, 0);
  @include utransition(visibility 200ms);
  @include utransition(background-color 300ms);
  
  position: absolute;
  top:0;
  left: 0;
  z-index: 110;
   
}

.blockhover--full__content{
  position: relative; 
  text-transform: uppercase;
  display: block;
  vertical-align: middle;
  text-align: center;
  z-index: 110;
  top: calc(50% - 10px);
  
  .blockhover--border{
    border: 2px solid $white;
    padding: $padding-xsm;
    color: $white;
    opacity: 1;
    
    &:hover{
               //border-color: $primary-color;
              // color: $primary-color;
		}
	}
}
	
div.blockhover--img{
	position: relative;
	margin-bottom: $padding-sm;
}


article:hover, div.blockhover--img:hover{
.blockhover--full{
	visibility: visible;
	background-color: rgba(184,183,184, 0.7);
		//@include utransition(visibility 200ms);
		@include utransition(background-color 200ms);
		
	}
}

.sidebar-visit .map{
	height: 200px;   
	}

.sidebar-visit, .sidebar-followus, .sidebar-social, .sidebar-service{
	background-color: $background-light;
	}

.sidebar-posts__img{
	float: left;
	width: 33.3333%;

	}
.sidebar-posts__title{
	float: right;
	width: 66.6667%;
	padding-left: 1rem;
	}

	.tags{
	//font-size: 0.8rem;
	padding: 0.33rem 0.5rem;
	margin: 0 0.5rem 0.5rem 0;
	background-color: $background-light;
	display: inline-block;
	font-size: 0.9rem;
	}
	.sidebar-icons img{
		padding-left: 0.5rem;
	}
	.social-icons img{
		padding-left: 0.75rem;
		
	}

.team__contact-icons{
	position: absolute;
	top: 0;
	right: 15px;
	
}

svg.svg-icon{
	width: 60px;
	height: 60px;
	//@include utransition(fill 400ms);
	 .a{
		 fill: $grey-light;
		 @include utransition(fill 400ms);
	 }
	 .b{
		 fill: $black;
	 }
}

a:first-child svg.svg-icon{
	.a{
		 fill: $grey-med;
	 }
}
a:nth-child(2) svg.svg-icon{
	a{
		 fill: $grey-light;
	 }
	
}
a:hover{
	svg.svg-icon{
		.a{
			fill: $grey-med;
		}
	}
}


.page__sitemap{

	dl{
		margin-bottom: 2rem;	
	}
	dd{
		margin-bottom: 1rem;
	}
	dd:first-of-type{
		margin-top: 1rem;
	}
}

// text columns for the browsers that support it
@media #{$medium}{
	.t--columns-3{
			@include prefix((
				column-count: 3,
				column-gap: 1.5em,
			), webkit moz);
		}
		
	.t--columns-2{
		@include prefix((
			column-count: 2,
			column-gap: 1.5em,
		), webkit moz);
		
	}
}


// set mobile/tablet logo to center and left for desktop

.header__top{
	> div:first-child{
		text-align: center;
	}
}

@media #{$large}{
	.header__top{
	> div:first-child{
		text-align: left;
		}
	}
}