@import '../settings';
@import 'vars';
@import 'mixins';
@import 'colors';


.form--grey{
    background-color: $grey-dark;
    color: $white;
    @include border-radius(0.3rem);
    &:active, &:focus, &:hover{
        background-color: $grey-dark;
    }
    font-size: 0.9rem;
    font-weight: 400;
    background-image: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

}

select.form--grey{
	background-image: url("../images/arrow-dropdown-sm-wh.svg");
}


// =========== FILTER BAR ===========//


.filter-bar{

  .filter-bar-title{
    display: inline;
    margin-right: 1rem;
  }
  form, label, select{
    display: inline;
  }
  label{
    color: $white;
    margin-right: 0.5rem;
  }
  select{
    width: 17%;
    margin-right: 2rem;
    margin-bottom: 0;
  }
}


@media #{$medium-only}{
  .filter-bar{
    .filter-bar-title{
      display: block;
      margin-bottom: $padding-xsm;
    }
    select{
      margin-right: 1%;
      width: 18%;
    }
  }
}

@media #{$small-only}{
  .filter-bar{
    .filter-bar-title{
      display: block;
    }
    label, select{
      display: block;
    }
    select{
      width: 100%;
      margin-bottom: $padding-xsm;
    }
  }


}

select, .select{
  @include border-radius(0.3rem);
  background-image: url("../images/arrow-dropdown-sm-black.svg");
  background-position: 95% center;
  background-repeat: no-repeat;
  height: 3rem;
	color: $black;

}

.filter-bar-title{
  color: $white;
  font-size: 1.3rem;

}

//=============== SEARCH BAR ================//


.search-box{
	position: relative;
	input.search-field{
		@include placeholder{
			color: $white;
		}
		
		&--page, &--grey{
			
			padding-right: 24px;
			&:focus, &:hover{
				
			}
			&::placeholder{
				color: $white;
			}
			color: $white;
		}
		&--inline{
			display: inline;
			width: auto;
			margin-bottom: 0;
		}

	}




	.search-btn{
		width: 20px;
		height: calc(100% - 2px);
		background: url("../images/search-btn.svg") no-repeat center center;
		position: absolute;
		padding: 1rem;
		top: 1px;
		right: 1px;
		@include border-radius(0);
		border-width: 0;
		@include utransition(background-color 300ms);
		&:hover{
			background-color: $black;
		}
	}
}

// =============== FLOAT LABELS ================ //

input.floatlabel, textarea.floatlabel{
	padding: 10px 10px;
	line-height: 1rem;
	height: inherit;
	font-size: 1rem;
	color: $black;
	font-weight: 400;

}
label.label-floatlabel{
	text-transform: uppercase;
	font-weight: 800;
	font-size: 0.8rem;
	left: 10px;
	color: $grey-dark;
}
input.active-floatlabel{
	color: $black;

}

input.floatlabel{
	&::-webkit-input-placeholder,
	&:-moz-placeholder,
	&::-moz-placeholder,
	&:-ms-input-placeholder {
  	 color: $grey-light;
	}
}


