/*
 * Default jvFloat theme.
 * modify it as you wish!
 */

.jvFloat {
	position: relative;
	//display: inline;
	margin-top: 1em;
}



.jvFloat {
	input, textarea{
		margin-bottom: 1.5rem;
		&:focus{
			border-color: #cccccc;
    
		}
	}
}
/* Start CSS3 Animations on supported browser */
.jvFloat .placeHolder {
	position: absolute;
	top: 0;
	left: 0px;
	width: auto;
	color: $black;
	font-size: .8em;
	font-weight: bold;
	-webkit-transform: translate(0, 0);
	-moz-transform: translate(0, 0);
	-o-transform: translate(0, 0);
	-ms-transform: translate(0, 0);
	transform: translate(0, 0);
	-webkit-transition: -webkit-transform 150ms, opacity 100ms, visibility 100ms;
	transition: transform 150ms, opacity 100ms, visibility 100ms;
	opacity: 0;
	visibility: hidden;
}

/*Allows textarea floating placeholder to be positioned distinctly from the normal .placeHolder class
 * This is required when working with Multiline text areas
 */
.jvFloat .placeHolder.textarea {
	/*top: 0px;*/
}

.jvFloat .placeHolder.active {
	display: block;
	visibility: visible;
	background-color: $grey-light;
	color: $black;
	padding: 3px 8px;
	width: 100%;
	font-size: 0.68rem;
	text-transform: uppercase;
	-webkit-transform: translate(0, -1.2rem);
	-moz-transform: translate(0, -1.2rem);
	-o-transform: translate(0, -1.2rem);
	-ms-transform: translate(0, -1.2rem);
	transform: translate(0, -1.2rem);
	-webkit-transition: -webkit-transform 100ms, opacity 120ms, visibility 120ms;
	transition: transform 100ms, opacity 120ms, visibility 120ms;
	opacity: 1;
	border-color: #cccccc;
    border-width: 1px 1px 0 1px;
    border-style: solid;
}

.jvFloat ::-webkit-input-placeholder{
	color: $black;
}
/* End CSS3 */

/* Legacy browser */
/*.jvFloat .placeHolder {
	position: absolute;
	top: -1em;
	left: 0;
	color: #0c61fc;
	font-size: .85em;
	font-weight: bold;
	opacity: 0;
	visibility: hidden;
}
.jvFloat .placeHolder.active {
	display: block;
	visibility: visible;
	opacity: 1;
}*/
/* End Legacy */
