$grid-column-count: 12;
//$xlarge: min-width\:1440px !global;
//add extra xlarge column classes
@mixin create_xlarge_columns{
  @for $i from 1 through $grid-column-count{
    .xlarge-#{$i}{
      width: $i / $grid-column-count * 100%;
    }
  }
}

@mixin create_xlarge_pushpull{
  @for $i from 1 through $grid-column-count{
    .xlarge-push-#{$i}{
      position: relative;
      left: $i / $grid-column-count * 100%;
      right: auto;
    }
    .xlarge-pull-#{$i}{
      position: relative;
      right: $i / $grid-column-count * 100%;
      left: auto;

    }
  }
}



// include in media query
@media(min-width:1440px){
  .row{
    max-width: 85rem; //reset max width for large page sizes
  }
  @include create_xlarge_columns; // add classes to breakpoint
  @include create_xlarge_pushpull; // add classes for push pull
  
}

@mixin create_xsmall_columns{
  @for $i from 1 through $grid-column-count{
    .xsmall-#{$i}{
      width: $i / $grid-column-count * 100%;
    }
  }
}

// include in media query
@media(max-width:480px){
  @include create_xsmall_columns; // add classes to breakpoint
  
}



